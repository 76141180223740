/* tslint:disable */
/* eslint-disable */
/**
 * Marco Email Parser API
 * Marco Email Parser API
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoyaltyProgramEnum = {
    SkyMiles: 'SkyMiles',
    MileagePlus: 'MileagePlus',
    AAdvantage: 'AAdvantage',
    MileagePlan: 'MileagePlan',
    MarriottBonvoy: 'Marriott Bonvoy',
    HiltonHonors: 'Hilton Honors',
    IhgOneRewards: 'IHG One Rewards',
    WorldOfHyatt: 'World of Hyatt',
    TrueBlue: 'TrueBlue',
    RapidRewards: 'Rapid Rewards',
    Gold: 'Gold',
    AmexEveryDay: 'Amex EveryDay',
    ThePlatinumCard: 'The Platinum Card',
    BlueBusinessPlus: 'Blue Business Plus',
    BusinessPlatinumCard: 'Business Platinum Card',
    ChaseFreedom: 'Chase Freedom',
    ChaseFreedomUnlimited: 'Chase Freedom Unlimited',
    ChaseFreedomFlex: 'Chase Freedom Flex',
    ChaseFreedomRise: 'Chase Freedom Rise',
    ChaseSapphire: 'Chase Sapphire',
    ChaseSapphirePreferred: 'Chase Sapphire Preferred',
    ChaseSapphireReserve: 'Chase Sapphire Reserve',
    Bilt: 'Bilt',
    WyndhamRewards: 'Wyndham Rewards',
    AccorLiveLimitless: 'Accor Live Limitless',
    InkBusinessUnlimited: 'Ink Business Unlimited',
    InkBusinessPlus: 'Ink Business Plus',
    InkBusinessPreferred: 'Ink Business Preferred',
    ChoicePrivilegesRewards: 'Choice Privileges Rewards',
    BestWesternRewards: 'Best Western Rewards',
    BiltRewards: 'Bilt Rewards',
    ChaseUltimateRewards: 'Chase Ultimate Rewards',
    MembershipRewards: 'Membership Rewards',
    BarclaycardRewardsProgram: 'Barclaycard Rewards Program',
    CapitalOneRewards: 'Capital One Rewards',
    KrisFlyerPartner: 'KrisFlyer Partner',
    AsiaMiles: 'Asia Miles',
    LeadersClub: 'Leaders Club',
    FlyingBlue: 'Flying Blue',
    BankOfAmericaTravelRewards: 'Bank of America Travel Rewards',
    CitiThankYouRewards: 'Citi ThankYou Rewards',
    Aeroplan: 'Aeroplan',
    TheExecutiveClub: 'The Executive Club',
    FlyingClub: 'Flying Club',
    AerClub: 'AerClub',
    EmiratesSkywards: 'Emirates Skywards',
    PrivilegeClub: 'Privilege Club',
    AviancaLifeMiles: 'Avianca LifeMiles',
    Skypass: 'Skypass',
    AeromexicoRewards: 'Aeromexico Rewards',
    AnaMileageClub: 'ANA Mileage Club',
    MilesMore: 'Miles & More',
    IberiaPlus: 'Iberia Plus',
    QantasFrequentFlyer: 'Qantas Frequent Flyer',
    BankOfAmericaPreferredRewards: 'Bank of America Preferred Rewards',
    VentureOneRewards: 'VentureOne Rewards',
    VentureRewards: 'Venture Rewards',
    VentureXRewards: 'Venture X Rewards'
} as const;
export type LoyaltyProgramEnum = typeof LoyaltyProgramEnum[keyof typeof LoyaltyProgramEnum];


export function instanceOfLoyaltyProgramEnum(value: any): boolean {
    for (const key in LoyaltyProgramEnum) {
        if (Object.prototype.hasOwnProperty.call(LoyaltyProgramEnum, key)) {
            if ((LoyaltyProgramEnum as Record<string, LoyaltyProgramEnum>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function LoyaltyProgramEnumFromJSON(json: any): LoyaltyProgramEnum {
    return LoyaltyProgramEnumFromJSONTyped(json, false);
}

export function LoyaltyProgramEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoyaltyProgramEnum {
    return json as LoyaltyProgramEnum;
}

export function LoyaltyProgramEnumToJSON(value?: LoyaltyProgramEnum | null): any {
    return value as any;
}
